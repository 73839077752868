
@tailwind base;
@tailwind components;
@tailwind utilities;

/********** Template CSS **********/
:root {
  --primary: #005608;
  --secondary: #fdb813;
  --light: #EFFDF5;
  --dark: #0E2E50;
}



.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}


/*** Navbar ***/
.nav-bar {
  position: relative;
  margin-top: 45px;
  padding: 0 3rem;
  transition: .5s;
  z-index: 9999;
}

.nav-bar.sticky-top {
  position: sticky;
  padding: 0;
  z-index: 9999;
}

.navbar {
  box-shadow: 0 0 30px rgba(0, 0, 0, .08);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-right: 5px;
  transition: .5s;
}

.navbar .dropdown-toggle[aria-expanded=true]::after {
  transform: rotate(-180deg);
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-size: 17px;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .nav-bar {
      margin: 0;
      padding: 0;
  }

  .navbar-light .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar-light .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

.navbar-light .navbar-brand {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 600;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      top: 100%;
      margin-top: 0;
      transform: rotateX(-75deg);
      transform-origin: 0% 0%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
      
  }

  .navbar .nav-item:hover .dropdown-menu {
      transform: rotateX(0deg);
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
@media (min-width: 992px) {
  .header {
      margin-top: -120px;
  }
}

.owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.owl-prev,
.owl-next {
  margin: 5px 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--secondary);
  border-radius: 40px;
  font-size: 20px;
  transition: .5s;
}

 .owl-prev:hover,
 .owl-next:hover {
  background: var(--dark);
}



.breadcrumb-item + .breadcrumb-item::before {
  color: #DDDDDD;
}

.drop_down {
  position: absolute;
  top: 130%;
  width: 130px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-wrap: wrap;
  background-color: #eee;
  overflow: hidden;
  z-index: 99999;
}

/*** Icon ***/
.icon {
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF !important;
  border-radius: 50px;
  border: 1px dashed var(--primary) !important;
}


/*** About ***/
.about-img img {
  position: relative;
  z-index: 2;
}

.about-img::before {
  position: absolute;
  content: "";
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: var(--primary);
  transform: skew(20deg);
  z-index: 1;
}


/*** Category ***/
.cat-item div {
  background: #FFFFFF;
  border: 1px dashed rgba(0, 185, 142, .3);
  transition: .5s;
}

.cat-item:hover div {
  background: var(--primary);
  border-color: transparent;
}

.cat-item div * {
  transition: .5s;
}

.cat-item:hover div * {
  color: #FFFFFF !important;
}


/*** Property List ***/
.nav-pills .nav-item .btn {
  color: var(--dark);
}

.nav-pills .nav-item .btn:hover,
.nav-pills .nav-item .btn.active {
  color: #FFFFFF;
}

.property-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, .08);
}

.property-item img {
  transition: .5s;
}

.property-item:hover img {
  transform: scale(1.1);
}

.property-item .border-top {
  border-top: 1px dashed rgba(0, 185, 142, .3) !important;
}

.property-item .border-end {
  border-right: 1px dashed rgba(0, 185, 142, .3) !important;
}


/*** Team ***/
.team-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, .08);
  transition: .5s;
}

.team-item .btn {
  color: var(--primary);
  background: #FFFFFF;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
}

.team-item .btn:hover {
  color: #FFFFFF;
  background: var(--primary);
}

.team-item:hover {
  border-color: var(--secondary) !important;
}

.team-item:hover .bg-primary {
  background: var(--secondary) !important;
}

.team-item:hover .bg-primary i {
  color: var(--secondary) !important;
}


/*** Testimonial ***/
.testimonial-carousel {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 576px) {
  .testimonial-carousel {
      padding-left: 4rem;
      padding-right: 4rem;
  }
}

.testimonial-carousel .testimonial-item .border {
  border: 1px dashed rgba(0, 185, 142, .3) !important;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 40px;
  top: calc(50% - 20px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 40px;
  font-size: 20px;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}


/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 35px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
  border-color: var(--light);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .form-control {
  border-color: rgba(255,255,255,0.5);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}


.left-down{
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
}

.php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.contact .info-wrap {
  background-color: var(--surface-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 30px;
}

.contact .info-item i {
  font-size: 20px;
  color: var(--accent-color);
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
  border: 1px solid color-mix(in srgb, var(--accent-color), transparent 40%);
  margin-left: 15px;
}

.contact .info-item h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 2px 0;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact .info-item:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.contact .php-email-form {
  background-color: var(--surface-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

@media (max-width: 575px) {
  .contact .php-email-form {
    padding: 20px;
  }
  .header .logo img{
    max-height: 45px;
  }
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  background-color: color-mix(in srgb, var(--background-color), transparent 50%);
  border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}
